/* ==========================================================================
   Header and Navigation Styles
   ========================================================================== */




/* ==========================================================================
    Vendor Image Gallery
    ========================================================================== */
.vendor-image-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
    justify-content: space-between;
}

.vendor-image-box {
    width: 100%;
    height: 55px;
    border: 1px solid #e7e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    text-align: center;
    background-color: white;
    transition: border-color 0.3s ease;
}

.vendor-image-box:hover {
    border-color: #888;
}

.vendor-image-box p {
    margin: 0;
    font-size: 1rem;
    color: #666;
    pointer-events: none;
}

.vendor-image-input {
    display: none;
}

.vendor-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}

.remove-image-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #edb7b7;
    right: -5px;
    top: -5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: 200ms ease;
}

.remove-image-btn:hover {
    scale: 1.2;
}

/* ==========================================================================
    Time Slots Styling
    ========================================================================== */
.time-slots-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.time_slot_wrapper {
    width: 100%;
}

.time-slot-btn {
    width: fit-content !important;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    font-size: 14px;
    white-space: nowrap;
}

.time-slot-text {
    display: inline-block;
}

.time-slots-label {
    width: 10%;
}

.edit_time_slot_label {
    width: 15%;
}

.time_slot_input {
    width: 40%;
}

.time_slot_btn {
    width: 60px;
    padding: 6px 12px;
}

.selected_slot {
    background-color: #9b4444 !important;
    color: #fff !important;
}

/* ==========================================================================
    Utility Classes
    ========================================================================== */
.nice_btn {
    width: 62px;
    height: fit-content;
    padding: 5px 8px;
}

.marging_2 {
    margin-top: 30px;
}

.delete-icon {
    cursor: pointer;
}

.review-card {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
}



/* ==========================================================================
    Input Wrappers
    ========================================================================== */
.edit_time_slot_label {
    position: relative;
    
}

.edit_time_slot_label_line {
    width: 100%;
    height: .5px;
    background-color: #d28d8d;
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
}

/* ==========================================================================
    Loading States
    ========================================================================== */
.full-page-loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

/* ==========================================================================
    Media Queries
    ========================================================================== */
@media (max-width: 768px) {

    .mobile_full_width{
        width: 100% !important;
    }

    /* Header */
    .winery_management_page_header {
        padding: 10px 15px;
    }

    .vendor-image-container {
        gap: .6rem;
    }

    /* Layout */
    .w-md-50 {
        width: 100% !important;
    }

    .field-wrapper {
        flex-direction: column;
        gap: 0;
    }

    .form-group {
        width: 100% !important;
    }

    /* Time Slots */
    .marging_2 {
        margin-top: 0;
    }

    .edit_time_slot_label,
    .time_slot_input,
    .time_slot_btn {
        width: 100%;
    }

    .time-slot-btn {
        width: 100%;
        justify-content: space-between;
        margin-right: 0 !important;
    }

    .time-slots-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    /* Vendor Images */
    .vendor-image-box {
        width: 45%;
    }

    /* inputs */
    .field-wrapper {}
    .edit_time_slot_label_line{
        display: block;
    }

    .form-group {
        margin-bottom: 1.5rem !important;
    }

    .text-black {
        margin-bottom: 3px !important;
    }
}

@media (max-width: 576px) {
    .vendor-image-box {
        width: 100%;
    }
}