a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0px;
}

img {
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

.menual_booking_action{
  display: flex;
  align-items: center;
  justify-content: start;
  gap:3px
}

.menual_booking_action button{
  font-size: 12px;
  border-radius: 5px;
  border:1px solid white;
  background-color: #ffdfd5;
  line-height: 16px;
  padding: 4px 8px;
  font-weight: 500;
  color: #ff8000;

}
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}

select:required:invalid {
  color: gray;
}

.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}

.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}

.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}

.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}

.react-tel-input .country-list {
  width: 250px !important;
}

.search-input {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}

.rmdp-container {
  width: 100%;
}

.rmdp-container input {
  width: 100%;
  height: 56px;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
}

.grid-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
}

.grid-images img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s;
}

.grid-images img:hover {
  transform: scale(1.05);
}

.scrollable-images {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.scrollable-images::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.scrollable-images img {
  flex: 0 0 auto;
  margin-right: 10px;
}

.field-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-control .css-13cymwt-control {
  border: none;
}

.form-control .css-t3ipsp-control {
  border: none;
}

.css-13cymwt-control {
  min-height: 26px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  word-wrap: break-word !important;
}

.css-13cymwt-control:hover {
  border: none !important;
  border-color: none !important;
  min-height: 26px;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1wy0on6 {
  display: none !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0;
  margin: 0;
}

.css-1nmdiq5-menu {
  width: 300px !important;
  word-wrap: break-word !important;
}

.css-t3ipsp-control {
  border: none !important;
  border-color: none !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 14px !important;
  outline: 0 !important;
  border-width: 0px !important;
  box-shadow: 0 0 0 1px transparent !important;
  word-wrap: break-word !important;
  overflow: hidden;
  max-width: 160px;
  min-height: 100% !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1wy0on6 {
  display: none !important;
}

.css-hlgwow {
  padding: 0px;
}

.modal-header {
  background-color: #9b4444 !important;
}

.modal-title {
  color: #fff !important;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 30px;
  font-weight: 100;
  color: #fff;
}

.border-bottom-only {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #9b4444;
  box-sizing: border-box;
  /* Ensures padding is included in the width */
}

.popup-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.email-icon {
  height: 120px;
}

.review-section h3 {
  font-size: 24px;
  font-weight: 700;
  color: #222222;
}

.accepting-terms {
  display: flex;
  align-items: start;
  gap: 9px;
  height: fit-content;
  margin-bottom: 0px; 
}
.accepting-terms a {
  color: #0e0e0e;
  text-decoration: underline !important;
}
.accepting-terms input {
  margin-top: 3px; 
}
.accepting-terms label {
  font-size: 15px;
  font-weight: 400;
  color: #5f5d5d;
  margin-bottom: 0px;
}

.review-card {
  padding: 20px 0px;
  border-bottom: 1px solid #22222247;
}

.review-card h1 {
  font-size: 20px;
  font-weight: 700;
  color: #222222;
}

.review-card p {
  font-size: 14px;
  font-weight: 400;
  color: #22222299;
}

.review-card .circle {
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.review-card .review-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #222222;
}

.toast-success {
  background-color: #fff;
}

.theme-outline-btn {
  border: 1px solid #222222;
  border-radius: 8px;
  padding: 12px;
  color: #222222;
}

.theme-outline-btn:hover {
  border: 1px solid #9b4444;
  color: #fff;
  background: #9b4444;
}

.theme-outline-btn:focus {
  border: 1px solid #9b4444;
  color: #fff;
  background: #9b4444;
}

.react-datepicker-wrapper {
  width: 100%;
}
.data_grid_body_for_one_data{
  height: 200px !important;
}

.filter_wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.filter_wrapper .picker {
  padding: 0px !important;
}
.filter_wrapper .picker .range-picker-input{
  /* display: none !important  ; */
  padding-top: 5px !important;
  border-radius: 8px !important;
  border: 1px solid #eeeeee !important;
}

.terms-and-conditions-pub {
  width: 80%;
  padding: 50px 0;
  margin: 0 auto;
}
.terms-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}
.terms-logo img {
  width: 100px;
}

@media (max-width: 768px) {
  .terms-and-conditions-pub {
    width: 90%;
  }
}
@media (max-width: 425px) {
  .terms-and-conditions-pub {
    width: 95%;
  }
}

