.loader-container {
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    background-color: var(--app-background);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    position: relative;
    animation: spin988 2s linear infinite;
  }
  
  .loader .circle {
    --dim: 1.2rem;
    width: var(--dim);
    height: var(--dim);
    background: var(--primary-color-gradient);
    border-radius: 50%;
    position: absolute;
  }
  
  .loader .circle:nth-child(1) {
    top: 0;
    left: 0;
  }
  
  .loader .circle:nth-child(2) {
    top: 0;
    right: 0;
  }
  
  .loader .circle:nth-child(3) {
    bottom: 0;
    left: 0;
  }
  
  .loader .circle:nth-child(4) {
    bottom: 0;
    right: 0;
  }
  
  @keyframes spin988 {
    0% {
      transform: scale(1) rotate(0);
    }
    20%, 25% {
      transform: scale(1.3) rotate(90deg);
    }
    45%, 50% {
      transform: scale(1) rotate(180deg);
    }
    70%, 75% {
      transform: scale(1.3) rotate(270deg);
    }
    95%, 100% {
      transform: scale(1) rotate(360deg);
    }
  }
  .button-loader-svg {
    width: 1.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
  }
  
  circle {
    fill: none;
    stroke-width: 4;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }/*# sourceMappingURL=ButtonLoader.css.map */